import React, { useState, useEffect  } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {Link, NavLink, useNavigate} from 'react-router-dom'
import { Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import ReactLoading from 'react-loading';
import Card from 'react-bootstrap/Card';
import img from '../../images/event1.jpg'
import api from '../../redux/api'
import DataTable from '../../components/DataTable';

function List() {
   const [events,setEvents] = useState([]);
   const [isLoading, setLoading] = useState(false);

//   const getEvents = (e) => {
//     //console.log('here loading',loading)
//     e.preventDefault()
//     api.getData('events').then(res => {
//     console.log('here res',res.data)
//     setEvents(res.data)
//    })
//   }
  useEffect(() => {
  // console.log('hereeeeee')
   api.getData('events').then(res => {
    //console.log('here res',res.data)
    if(res.data.length > 0)
    {
      setEvents(res.data)
      setLoading(true)
    }
    else
    {
      //console.log('here res',res.data)
      setEvents([])
      setLoading(true)
    }
    
   })
   
  }, []);

//   useEffect(() => {
//    if (isLoading) {
//       setLoading(false)
//    //   simulateNetworkRequest().then(() => {
//    //     setLoading(false);
//    //   });
//    }
//  }, [isLoading]);

 const handleLoading = () => setLoading(true);

  return (
      <div>
         <div className='pageBar'>
               <div>Search</div>
               <div>
                     <Link to='/events/add'>
                     <Button style={{backgroundColor:'#264653',color:'#EDF6F9', border:'0'}}>+ Event
                     </Button>
                     </Link>
               </div>
         </div>
           
        { isLoading ? (
         <div>     
               { events.length > 0 ? (
               <Row style={{padding:'.3%'}}>
                  {events.map(function(event, i){
            
                  return <Col key={event.id}  md={3} sm={12} lg={3}>
                     <Card style={{width:'100%', border:'0', display:'flex',padding:'10px',backgroundColor:'white'}} >
                        <Card.Img variant="top"  style={{ width:'100%'}} src={img} />
                        <Card.Body className='welcomeCard'>
                           <Card.Title style={{color:'#264653'}}>{event.name}</Card.Title>
                           <Card.Text style={{color:'#264653'}}>
                              {event.description}
                           </Card.Text>
                           <Card.Subtitle className='mb-2' style={{fontSize:'12px',color:'#264653'}}>Event By: {event.author}</Card.Subtitle>
                           <Link to={'/event/'+ event.id}>
                           <Button style={{backgroundColor:'#023047',color:'#EDF6F9', border:'0'}}>See More</Button>

                           </Link>
                        </Card.Body>
                     </Card>
                  </Col>
               
                  })}
               </Row>
               ) : 
               <Row style={{padding:'.3%'}}>
                  <Col  md={12}>
                     <Card style={{width:'100%', border:'0', display:'flex',padding:'10px',backgroundColor:'white'}} >                  
                        <Card.Body className='welcomeCard'>
                           <Card.Title style={{color:'#264653'}}>No Events</Card.Title>
                        </Card.Body>
                     </Card>
                  </Col>   
               </Row>
               }             
         </div>
            ) :
         <div className='loadingSpin'>
            <ReactLoading type={'spinningBubbles'} color={'#264653'} height={'15%'} width={'15%'} />
         </div>}

      </div>
         
  )
}

export default List