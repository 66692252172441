import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import api from "../redux/api";
import { addUser, loginUser, logOutUser } from "../redux/userSlice";
export default function Footer() {
  const users = useSelector((state) => state.users.items);
  const { userLoggedIn } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  return (
    <div className="footer">
      
        <div>
          <p>Copyright © 2023.</p>
        </div>
        <div>
          <ul>
            <li className="pr-5">
             
              {/* <NavLink to="/arabic"  style={{  textDecoration : 'none',color:'#CAF0F8',listStyle: 'none', padding:'2px'}}> Arabic</NavLink> */}
            </li>
          </ul>
        </div>
    
    </div>
  );
}
