import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Card from 'react-bootstrap/Card';
import CounterInput from 'react-bootstrap-counter'
import api from '../../redux/api';
import { Button } from 'react-bootstrap';
import ReactLoading from 'react-loading';

function TicketPage() {
   const [details,setDetails] = useState([]);
   const [amount,setAmount] = useState(1)
   const { id } = useParams()

   const [isLoading, setLoading] = useState(false);

   useEffect(() => {
      api.getData('events/'+id).then(res => {
       //console.log('here res',res.data.event)
       window.localStorage.setItem('EVENT_ID',JSON.stringify(id))
       window.localStorage.setItem('NUM_OF_TICKETS',JSON.stringify(amount))
       setDetails(res.data.event)
       setLoading(true)      
      })
      
     }, []);

   const buyTicket = (e) => {
     // console.log('amount',amount)
     setLoading(false)
      let final_price = amount * details.price
      e.preventDefault()
      let user_token =  JSON.parse(window.localStorage.getItem('CURRENT_USER_TOKEN'))
      let token3 = ''
      let data = {'event_id' : id, 'event_price' : final_price,'amount':amount}
     // console.log('here in token api',details)
      api.passToken('ticket/payment',user_token,data).then(res => {
        //console.log('here in token api',res.data)
        token3 = res.data.token3
        if(res)
        {
          let iframURL = `https://accept.paymob.com/api/acceptance/iframes/759204?payment_token=${token3}`
          window.location.href = iframURL
        }
    })
      }
   const decrease = (e) => {
      if(amount > 1 )
    {
      setAmount(amount -1 )
     // window.localStorage.setItem('NUM_OF_TICKETS',JSON.stringify(amount))
    }
    }
   const increase = (e) => {      
      setAmount(amount + 1 )
     // window.localStorage.setItem('NUM_OF_TICKETS',JSON.stringify(amount))
    
    }

    useEffect(() => {
      window.localStorage.setItem('NUM_OF_TICKETS',JSON.stringify(amount))
   }, [amount]);

 
  return (
    <div style={{border: '3px solid #006D77', margin: 'auto',width: '50%',padding: '10px',backgroundColor:'#83C5BE'}} className='mt-5 ticketCard'>
        {
          isLoading ? (
            <div style={{backgroundColor:'#83C5BE'}}>
            <Card style={{width:'100%', border:'0', display:'flex',padding:'0px',backgroundColor:'#83C5BE'}} >
              <Card.Body  className='welcomeCard' >
                  <Card.Title style={{color:'#264653',fontSize:'25px'}}>{details.name}</Card.Title>
                  <Card.Text style={{color:'#264653'}}>Amount: 
                    <div style={{display:'flex',height:'15%'}}>
                      <Button onClick={decrease} style={{backgroundColor:'#023047',color:'#EDF6F9', border:'0',margin:'2px',width:'40px'}}>-</Button>
                      {/* <CounterInput min={1} onChange={ ( value ) => { setAmount(value) } } >{amount}  </CounterInput>  */}
                      <p style={{color:'#EDF6F9',margin:'10px'}}>{amount}</p>
                      <Button onClick={increase} style={{backgroundColor:'#023047',color:'#EDF6F9', border:'0',margin:'2px',width:'40px'}}>+</Button>
                    </div> 
                  </Card.Text>
                  <Card.Subtitle className='mb-2' style={{fontSize:'12px',color:'#264653'}}></Card.Subtitle>
                  <Link to={'/event/'+ id}>
                    <Button style={{backgroundColor:'#023047',color:'#EDF6F9', border:'0'}} onClick={buyTicket}>Proceed to checkout</Button>
                  </Link>
                </Card.Body>
            </Card>
            </div>
          ) :
          <div className='loadingSpin'>
            <ReactLoading type={'spinningBubbles'} color={'#264653'} height={'15%'} width={'15%'} />
         </div>
        }
    </div>
  )
}

export default TicketPage