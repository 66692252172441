/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect  } from 'react'
import { GoogleLogin } from '@leecheuk/react-google-login';
import { useDispatch, useSelector } from 'react-redux';
import {Link, NavLink, useNavigate} from 'react-router-dom'
import api from '../redux/api'
import { addUser,loginUser } from '../redux/userSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons'
import { LoginSocialFacebook,LoginSocialGoogle } from 'reactjs-social-login';
import { FacebookLoginButton,GoogleLoginButton } from "react-social-login-buttons";
import ReactModal from 'react-modal';
import { Button } from 'react-bootstrap';
import PopUp from '../components/PopUp';
export default function SignIn() {
  const [email,setEmail] = useState();
  const [password,setPassword] = useState();
  const [isOpen, setIsOpen] = useState(false); //popup
  const navigate = useNavigate();
  //const users = useSelector(state => state.users.items)
  const {loading} = useSelector(state => state.users)
  const dispatch = useDispatch()
  const userData = {email,password}
  const [loginStatus,setLoginStatus] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [errorsMessage, setErrorsMessage] = useState();

  const [profile,setProfile] = useState();

  const togglePassword =(e)=>{
    e.preventDefault()
    if(passwordType==="password")
    {
    setPasswordType("text")
    return;
    }
    setPasswordType("password")
  }
  const handleLogin = (e) => {
    e.preventDefault()
    if(email && password)
    {
      if(loginStatus === false)
      { 
        api.postData('users/login',userData)
        .then(res => {
        if(res.data.status === 'success')
        {
          let token = res.data.token
          window.localStorage.setItem('CURRENT_USER_TOKEN',JSON.stringify(token))
          

          setLoginStatus(true)
          dispatch(loginUser(res.data.user))
          //console.log('one',loading)
          navigate("/");
        }
      })
        .catch(response => {
        setErrorsMessage(response.response.data.message)
        setIsOpen(true)
        
        //console.log('errooor',response.response.data.message)
      })
      }
    }
    else {
      setErrorsMessage("Missing email or password")
      setIsOpen(true)
    }
    
   
  }
  //to get url of login frame 
  const responseGoogle = (response) => {
    // Handle the response from Google in your React component
    console.log('google login',response)
  };

  const handleGoogleSignInSuccess = (response) => {
    // Handle successful sign-in
    console.log('Google sign-in success:', response);
    let googleToken = response.data.access_token
    api.socialLogin('auth/google/callback',{'googleToken':googleToken}).then(res => {
      //console.log('here the response number 1',res)
      if( res.data.status ==='success')
      {
       // console.log('goooooooooooooooooooooooood')
          window.localStorage.setItem('CURRENT_USER_TOKEN',JSON.stringify(googleToken))
          setLoginStatus(true)
          dispatch(loginUser(res.data.user))
          navigate("/");
      }
    })
  };
  const handleGoogleSignInFailure = (error) => {
    if (error.error === 'popup_closed_by_user') {
      // Handle popup closed by user
      console.log('Google sign-in popup closed by user');
    } else {
      // Handle other sign-in errors
      console.error('Google sign-in error:', error);
    }
  };
  
  useEffect(() => {

  },[loginStatus])

  useEffect(() => {

  },[errorsMessage])

  return (
    <div className='row justify-content-center pt-5'>
      <div className='col-sm-6'>
       <div className='card p-4' style={{backgroundColor:'#CAF0F8'}}>
       <form >
          <div className="form-row">
              <div className="form-row">
           
              </div>
              <div className="form-group col-md-12 pb-2">
                {/* <label>Email</label> */}
                <input type="email" className="form-control" 
                placeholder="Email" required onChange={(e)=> {setEmail(e.target.value)}}/>
              </div>
              <div className="form-group col-md-12 pb-2 password-container" >
                {/* <label>Password</label> */}
                  <input type={passwordType} className="form-control"  
                    placeholder="Password" required onChange={(e)=> {setPassword(e.target.value)}}/>
                    <div className="input-group-btn">
                    { passwordType==="password"? <FontAwesomeIcon icon={faEyeSlash} className='eye' onClick={togglePassword} style={{top:'25%',right:'5%'}}/> : <FontAwesomeIcon icon={faEye} className='eye' onClick={togglePassword} style={{top:'25%',right:'5%'}}/> }
                          {/* <button className="btn btn-outline-none" >
                        
                          </button> */}
                    </div>
              </div>
              <div >
                <ReactModal style={{
                  overlay: {
                    position: 'fixed',
                    zIndex: 1020,
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(255, 255, 255, 0.75)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  },
                  content: {
                    background: '#CAF0F8',
                    color:'#023047',
                    width:'40%',
                    maxWidth: 'calc(100% - 2rem)',
                    maxHeight: 'calc(100% - 2rem)',
                    overflowY: 'auto',
                    position: 'relative',
                    
                    borderRadius: '0.3rem',
                    textAlign:'center',
                    fontSize:'16px'
                  }}}
                  isOpen={isOpen}
                  contentLabel="Example Modal"
                  onRequestClose={() => setIsOpen(false)}
                  portalClassName="popup"
                  ariaHideApp={false}
                >
               <p style={{border: 'none'}}> {errorsMessage ? errorsMessage : ''}</p>  
                  <Button style={{marginLeft:'4%',backgroundColor:'#023047',border:'none', fontSize:'13px'}} onClick={(e) => {
                    e.preventDefault()
                    setIsOpen(false)
                  }}> Ok</Button>
                </ReactModal>
              </div>
            
          </div>      
          <div className='d-flex align-items-center flex-column'>
            <div className='m-2'>
            <NavLink to='/signup' style={{ color:'#023047'}}> Don't have an account? Register</NavLink>
            </div>
            <button type="submit" className="btn btn-primary" onClick={handleLogin} style={{backgroundColor:'#023047', color:'#CAF0F8', border:'0'}}>Sign in</button>
           
            <LoginSocialGoogle
            isOnlyGetToken
            client_id={'792610180371-ufl37t7l7p4u7g4sam1fe4fqo001o32c.apps.googleusercontent.com' || ''}
            onLoginStart={console.log("HEEELO IS REonLoginStart")}
            onResolve={handleGoogleSignInSuccess}
            onReject={handleGoogleSignInFailure}
            redirect_uri={'http://egypt-calendar.com/auth/google/callback'}
            //redirectUri={'https://f361-197-58-207-189.ngrok-free.app/auth/google/callback'}
            // onResolve={({ provider, data }) => {
            // console.log(provider)
            // console.log(data)
            // }}
            // onReject={(err) => {
            //   console.log(err)
            // }}
          >
            <GoogleLoginButton />
          </LoginSocialGoogle>

          {/* <GoogleLogin
            clientId="792610180371-ufl37t7l7p4u7g4sam1fe4fqo001o32c.apps.googleusercontent.com"
            buttonText="Login with Google"
            // onSuccess={responseGoogle}
            // onFailure={responseGoogle}
            onSuccess={handleGoogleSignInSuccess}
            onFailure={handleGoogleSignInFailure}
            cookiePolicy={'single_host_origin'}
            plugin_name={'react laravel login with google'}
          /> */}
            
          </div>
          
        </form>
        {/* <p>test test {loading? 'true' : 'false'}</p> */}
       </div>
       
        
      </div>
      
      
    </div>
  )
}
