import React, {Component} from 'react'
import "bootstrap/dist/css/bootstrap.min.css" 
import { BrowserRouter, Route,Routes } from 'react-router-dom';
import './App.css';
import Header1 from './components/Header1';
import Home from './modules/Home';
import SignIn from './modules/SignIn'
import SignUp from './modules/SignUp'
import Footer from './components/Footer';
import EventShow from './modules/Events/Show';
import Events from './modules/Events/List';
import AddEvents from './modules/Events/Add';
import Profile from './modules/User/Profile';
import PaymentCallback from './modules/Events/PaymentCallback';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TicketPage from './modules/Events/TicketPage';
function App() {
  
  return (
   <BrowserRouter>
     <div className="App">
      <div className='content'>
      <ToastContainer />
      <Header1/>
       <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/login' element={<SignIn/>}/>
          <Route path='/signup' element={<SignUp/>}/>
          <Route path='/event/:id' element={<EventShow/>}/>
          <Route path='/events' element={<Events/>}/>
          <Route path='/events/add' element={<AddEvents/>}/>
          <Route path='/profile' element={<Profile/>}/>
          <Route path='/payment/callback' element={<PaymentCallback/>}/>
          <Route path='/event/:id/ticket' element={<TicketPage/>}/>
          {/* <Route path="/auth/google" component={<LoginGoogle/>} /> */}
       </Routes>
      
      </div>
      <footer>
       <Footer/>
       </footer>
    </div>
   </BrowserRouter>
  );
}

export default App;



// plans.forEach((plan , i) => {
  
//   let result = []
//   features.forEach(feature => {
//     let count = 0 
//     let db = {}
//     db.feature_name = feature.name

    
// })

  
// })

