import React, { useState, useEffect  } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {Link, NavLink, useNavigate} from 'react-router-dom'
import { Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import api from '../redux/api'
import { addEvent } from '../redux/eventSlice';
import ReactLoading from 'react-loading';

import img from '../images/event1.jpg'
function RecentEvents() {
  const [events,setEvents] = useState([]);
  const [isLoading, setLoading] = useState(false);

  // const getEvents = (e) => {
  //   //console.log('here loading',loading)
  //   e.preventDefault()
  //   api.getData('events').then(res => {
  //   console.log('here res',res.data)
  //   setEvents(res.data)
   
  //  })
  // }
  useEffect(() => {
  // console.log('hereeeeee')
   api.getData('events').then(res => {
    //console.log('here res',res.data)
    if(res.data.length > 0)
    {
      setEvents(res.data)
      setLoading(true)
    }
    else
    {
      //console.log('here res',res.data)
      setEvents([])
      setLoading(true)
    }
   
   })
   
  }, []);

  const handleLoading = () => setLoading(true);
  
  return (
    <div>
    
    <Row>
      <Col>
        <Card style={{width:'100%', border:'0', display:'flex',paddingRight:'1%',paddingLeft:'1%',backgroundColor:'white'}} >
        {/* <Card.Img variant="top"  style={{ backgroundColor: 'red' }} /> */}
          <Card.Body className='welcomeCard' style={{backgroundColor:'#023047'}}>
            <Card.Title style={{color:'#EDF6F9'}}>Recent Events</Card.Title>
          </Card.Body>
        </Card>
      </Col>
    </Row>
   
   {
    isLoading ? (
      <div>
        {
          events.length > 0 ? (
            <Row style={{padding:'.3%'}}>
                {events.map(function(event, i){
                  if(i < 4)
                    {
                      return <Col key={event.id} md={3} sm={12} lg={3}>
                          <Card style={{width:'100%', border:'0', display:'flex',padding:'10px',backgroundColor:'white'}} >
                              <Card.Img variant="top"  style={{ width:'100%'}} src={img} />
                              <Card.Body className='welcomeCard'>
                                  <Card.Title style={{color:'#023047'}}>{event.name}</Card.Title>
                                  <Card.Text style={{color:'#023047'}}>
                                    {event.description}
                                  </Card.Text>
                                  <Card.Subtitle className='mb-2' style={{fontSize:'12px',color:'#023047'}}>Event By: {event.author}</Card.Subtitle>
                                  <Link to={'/event/'+ event.id}>
                                  <Button style={{backgroundColor:'#023047',color:'#EDF6F9', border:'0'}}>See More</Button>

                                  </Link>
                              </Card.Body>
                          </Card>
                      </Col>
                    }
                  })}
            </Row>
          ) :
          <Row style={{padding:'.3%'}}>
                
                  
                      <Col>
                          <Card style={{width:'100%', border:'0', display:'flex',padding:'10px',backgroundColor:'white'}} >
                              <Card.Body className='welcomeCard'>
                                  <Card.Title style={{color:'#023047'}}>No Events</Card.Title>
                                  <Card.Text style={{color:'#023047'}}>
                                  </Card.Text>
                              </Card.Body>
                          </Card>
                      </Col>
            </Row>
        }
      </div>

    ) : 
      <div className='loadingSpin'>
         <ReactLoading type={'spinningBubbles'} color={'#023047'} height={'15%'} width={'15%'} />
      </div>
   }

    </div>
  )
}

export default RecentEvents