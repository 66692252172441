import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addUser, loginUser } from '../redux/userSlice';
import { newAddUser } from './../redux/api';
import {Link, NavLink,useNavigate} from 'react-router-dom'
import api from '../redux/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons'
import ReactModal from 'react-modal';
import { Button } from 'react-bootstrap';
import { LoginSocialFacebook,LoginSocialGoogle } from 'reactjs-social-login';
import { FacebookLoginButton,GoogleLoginButton } from "react-social-login-buttons";

export default function SignIn() {

  const [first_name,setFirstName] = useState();
  const [last_name,setLastName] = useState();
  const [email,setEmail] = useState();
  const [password,setPassword] = useState();
  const [mobile,setMobile] = useState();
  const [isOpen, setIsOpen] = useState(false); //popup
  const [passwordType, setPasswordType] = useState("password");
  const [errorsMessage, setErrorsMessage] = useState();

  const users = useSelector(state => state.users.items)
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const userData = {first_name,last_name,email,password,mobile}
  const [loginStatus,setLoginStatus] = useState(false);
  const togglePassword =(e)=>{
    e.preventDefault()
    if(passwordType==="password")
    {
    setPasswordType("text")
    return;
    }
    setPasswordType("password")
  }
  //const [email,setEmail] = useState();
  const handleSubmit = (e) => {
   e.preventDefault()
   if(first_name && last_name && email && password)
    { 
      
      api.postData('users/signup',userData)
      .then(res => {
       console.log('here res',res.data)
        if(res.data.status == 'success')
        {
          window.localStorage.setItem('CURRENT_USER_TOKEN',JSON.stringify(res.data.token))

          dispatch(addUser({first_name,last_name,email,password,mobile}))
          dispatch(loginUser({email,password}))
          //console.log('here loading',loading) 
          navigate("/");
        }
       })
       .catch(response => {
        console.log('errooor',response.response) /////////////
        setErrorsMessage(response.response)
        setIsOpen(true)
        
        //console.log('errooor',response.response.data.message)
      })
    }
    else {
      setErrorsMessage("Missing Fields")
      setIsOpen(true)
    }
   

   //newAddUser(userData,dispatch)
 }
  const checkData = (e) => {
   e.preventDefault()
   if(first_name && last_name && email && password )
    { 
      dispatch(addUser({first_name , last_name,email,password,mobile}))
    }
    else {
      console.log('err data')
    }
 }

 const handleGoogleSignInSuccess = (response) => {
  let googleToken = response.data.access_token
  api.socialLogin('auth/google/callback',{'googleToken':googleToken}).then(res => {
    console.log('here the response number 1',res)
    if( res.data.status ==='success')
    {
        window.localStorage.setItem('CURRENT_USER_TOKEN',JSON.stringify(googleToken))
        setLoginStatus(true)
        dispatch(loginUser(res.data.user))
        navigate("/");
    }
    else {
      console.log('here hehre')
      setErrorsMessage(res.data.message)
      setIsOpen(true)
    }
  })
};

const handleGoogleSignInFailure = (error) => {
  if (error.error === 'popup_closed_by_user') {
    // Handle popup closed by user
    console.log('Google sign-in popup closed by user');
  } else {
    // Handle other sign-in errors
    console.error('Google sign-in error:', error);
  }
};
  useEffect(() => {

  },[errorsMessage])

  return (
    <div className='row justify-content-center pt-5'>
      <div className='col-sm-6'>
       <div className='card p-4' style={{backgroundColor:'#CAF0F8'}}>
       <form onSubmit={handleSubmit}>
          <div className="form-row">
           <div className="form-row">
            <div className="form-group col-md-12 pb-2">
              {/* <label>Name</label> */}
              <input type="text" className="form-control"  
              placeholder="First Name" required onChange={(e)=> {setFirstName(e.target.value)}}/>
            </div>
            </div>
           <div className="form-row">
            <div className="form-group col-md-12 pb-2">
              {/* <label>Name</label> */}
              <input type="text" className="form-control"  
              placeholder="Last Name" required onChange={(e)=> {setLastName(e.target.value)}}/>
            </div>
            </div>
            <div className="form-group col-md-12 pb-2">
              {/* <label>Email</label> */}
              <input type="email" className="form-control" 
               placeholder="Email" required onChange={(e)=> {setEmail(e.target.value)}}/>
            </div>
            <div className="form-group col-md-12 pb-2 password-container" style={{width:'100%'}}>
              {/* <label>Password</label> */}
                    <input type={passwordType} className="form-control"  
                         placeholder="Password" required onChange={(e)=> {setPassword(e.target.value)}}/>
                    <div className="input-group-btn">
                    { passwordType==="password"? <FontAwesomeIcon icon={faEyeSlash} className='eye' onClick={togglePassword} style={{top:'25%',right:'5%'}}/> : <FontAwesomeIcon icon={faEye} className='eye' onClick={togglePassword} style={{top:'25%',right:'5%'}}/> }
                          {/* <button className="btn btn-outline-none" >
                        
                          </button> */}
                    </div>
            </div>
            <div className="form-group col-md-12">
             
              <input type="text" className="form-control"  
              placeholder="Mobile"  onChange={(e)=> {setMobile(e.target.value)}}/>
          </div>
            <div >
                <ReactModal style={{
                  
                  overlay: {
                   position: 'fixed',
                   zIndex: 1020,
                   top: 0,
                   left: 0,
                   width: '100%',
                   height: '100%',
                   background: 'rgba(255, 255, 255, 0.75)',
                   display: 'flex',
                   alignItems: 'center',
                   justifyContent: 'center',
                 },
                 content: {
                   background: '#CAF0F8',
                   color:'#023047',
                   width:'40%',
                   maxWidth: 'calc(100% - 2rem)',
                   maxHeight: 'calc(100% - 2rem)',
                   overflowY: 'auto',
                   position: 'relative',
                   
                   borderRadius: '0.3rem',
                   textAlign:'center',
                   fontSize:'16px'
                 }}}
                  isOpen={isOpen}
                  contentLabel="Example Modal"
                  onRequestClose={() => setIsOpen(false)}
                  portalClassName="popup"
                  ariaHideApp={false}
                >
               <p style={{border: 'none'}}> {errorsMessage ? errorsMessage : ''}</p>  
                
                {/* <hr style={{border: 'none'}} /> */}
                  <Button style={{marginLeft:'4%',backgroundColor:'#023047',border:'none', fontSize:'13px'}}
                  onClick={(e) => {
                    e.preventDefault()
                    setIsOpen(false)
                  }}> Ok</Button>
                </ReactModal>
            </div>
          </div>

          
          <div className='d-flex align-items-center flex-column'>
          <NavLink to='/login' className='m-2' style={{ color:'#023047'}}> already have an account? Login</NavLink>
          <button type="submit" className="btn btn-primary" 
          style={{backgroundColor:'#023047', color:'#CAF0F8', border:'0'}} onClick={handleSubmit}>Sign Up</button>
          
          <LoginSocialGoogle
            isOnlyGetToken
            client_id={'792610180371-ufl37t7l7p4u7g4sam1fe4fqo001o32c.apps.googleusercontent.com' || ''}
            onLoginStart={console.log("HEEELO IS REonLoginStart")}
            onResolve={handleGoogleSignInSuccess}
            onReject={handleGoogleSignInFailure}
            redirect_uri={'http://egypt-calendar.com/auth/google/callback'}
          >
            <GoogleLoginButton />
          </LoginSocialGoogle>

          </div>
         
        </form>
       </div>
        
      </div>
      
    </div>
  )
}
