import { createSlice } from '@reduxjs/toolkit'
import { act } from 'react-dom/test-utils'

export const eventSlice = createSlice({
  name: 'events',
  initialState: {
    items: [],
    item:[],
    loading:false,
    numOfTickets:1,
  },
  reducers: {
   
    addEvent: (state, action) => {
      //console.log('add Event',action)
      state.loading=true
      state.items.push(action.payload)
      
      state.loading=false
      
    },
    editEvent: (state, action) => {
      state.loading=true
      state.items.map(item => {
        if(item.id == action.payload.id)
        {
         //  item.title = action.payload.title
         //  item.description = action.payload.description
        }
      })
      state.loading=false
     
    },
    deleteEvent: (state, action) => {
      state.loading=true
      console.log('delete',action)
       state.items = state.items.filter(item => 
        item.id != action.payload.id
       )
       state.loading=false
    },
    
  }
})

// Action creators are generated for each case reducer function
export const { addEvent,editEvent,deleteEvent} = eventSlice.actions

export default eventSlice.reducer