/* eslint-disable import/no-anonymous-default-export */
// import {addUser } from "./userSlice"
// import axios from 'axios'

// export const newAddUser = async (user, dispatch)=> {
//    dispatch(addUser())
//    try {
//       const res = await axios.post('http://localhost:8000/api/users/signup',user)
//       console.log('resssssssssss',res.data)
//    }
//    catch(err){
//       // dispatch(errorUser())

//    }
// }
import axios from "axios";
const instance = axios.create({
   baseURL: "https://api.egypt-calendar.com/api",
  //baseURL: "http://localhost:8000/api",
  //baseURL: process.env.APP_BASE_URL,
  timeout: 150000000, // Request timeout
  headers: {
    "content-type": "application/octet-stream",
    "x-rapidapi-host": "example.com",
    "x-rapidapi-key": process.env.RAPIDAPI_KEY,
  },
});
export default {
  getData: (url,params) =>
  instance({
      method: "GET",
      url:`/${url}`,
    //  params:params,
      // params: {
      //   search: "parameter",
      // },
    }),
  postData: (url,payload) =>
    instance({
      method: "POST",
      url:`/${url}`,
      data: payload,
      headers: {
        "content-type": "application/json", // override instance defaults,
        'Authorization': 'Bearer ' + JSON.parse(window.localStorage.getItem('CURRENT_USER_TOKEN'))
      },
    }),
  socialLogin: (url,params) =>
  instance({
    method: "GET",
    url:`/${url}`,
    params: params,
    // data: payload,
    // headers: {
    //   "content-type": "application/json", // override instance defaults
    //    'Authorization': 'Bearer ' +token
    // },
    }),
  passToken: (url,token,data) =>
    instance({
      method: "POST",
      url:`/${url}`,
      data: data,
      // data: payload,
      headers: {
        "content-type": "application/json", // override instance defaults
         'Authorization': 'Bearer ' +token
      },
    }),
   
};
