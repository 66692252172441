import React, { useState, useEffect  } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {Link, NavLink, useNavigate, useParams} from 'react-router-dom'
import { Row, Col } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';
import api from '../../redux/api'
import img from '../../images/event1.jpg'
import axios from "axios";
import ReactLoading from 'react-loading';


function Show() {
  const [details,setDetails] = useState([]);
  const { id } = useParams()
  const [isLoading, setLoading] = useState(false);
 
  useEffect(() => {
   api.getData('events/'+id).then(res => {
    //console.log('here res',res.data.event)
    window.localStorage.setItem('EVENT_ID',JSON.stringify(id))
    setDetails(res.data.event)
    setLoading(true)

    //console.log('details',res.data.event)
   
   })
   
  }, []);
  const buyTicket = (e) => {
    e.preventDefault()
    let user_token =  JSON.parse(window.localStorage.getItem('CURRENT_USER_TOKEN'))
    let token3 = ''
    let data = {'event_id' : id, 'event_price' : details.price}
    console.log('here in token api',details)
    api.passToken('ticket/payment',user_token,data).then(res => {
      console.log('here in token api',res.data)
      token3 = res.data.token3
      if(res)
      {
        let iframURL = `https://accept.paymob.com/api/acceptance/iframes/759204?payment_token=${token3}`
        window.location.href = iframURL
      }
  })
    }

  return (
    <div>
      <div className='show-event'>
       <h3>Event Details</h3>
      </div>
    {isLoading ? (
      <div>
      <Container className='mt-3 mb-3' style={{border:'solid #83C5BE',color:'#023047',fontSize:'22px'}}>
        <Row className='mt-3 mb-3' >
            <Col style={{alignItems:'start'}}>
              <img src={img} style={{ width: '60%' ,border:'0',borderRadius:'0'}}/>
            </Col>
            <Col style={{alignItems:'start'}}>
              <Row>
              <small style={{fontSize:'10px',color:'#83C5BE'}}>Name</small>
              <p>  {details.name}</p>
              </Row>
              <Row>
              <small style={{fontSize:'10px',color:'#83C5BE'}}>Description</small>
              <p> {details.description}</p>  
              </Row>
            </Col>
         </Row>
       <Row className='mt-3 mb-3' style={{marginLeft:'5px'}}>
         <Col style={{alignItems:'start'}}>
          <Row>
          <small style={{fontSize:'10px',color:'#83C5BE'}}>Event By</small>
            <p> {details.author}</p> 
          </Row>
          <Row>
          <small style={{fontSize:'10px',color:'#83C5BE'}}>Price</small>
            <p>{details.price/100} EGP</p> 
          </Row>
         </Col>
          <Col style={{alignItems:'start'}}>
            <Row>
            <small style={{fontSize:'10px',color:'#83C5BE'}}>Date & Time</small>
            <p>{details.date} | {details.time}</p> 
            </Row>
            <Row>
            <small style={{fontSize:'10px',color:'#83C5BE'}}>Location</small>
            <p>{details.location} </p>
            </Row>
          </Col>
          <Col className='buy-button'>
            <Row>     
              <p></p>
            </Row>
            <Row className='buy-button'>
            <Link to={'/event/'+ id+'/ticket'}>
            <Button style={{backgroundColor:'#023047',color:'#EDF6F9', border:'0'}} >Buy Ticket</Button>
            </Link>
            </Row>
          </Col>
        </Row>
      </Container>
      </div>

    ):
    <div className='loadingSpin'>
            <ReactLoading type={'spinningBubbles'} color={'#264653'} height={'15%'} width={'15%'} />
         </div>
    }
    </div>
  )
}

export default Show