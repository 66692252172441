import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {Link, NavLink, useNavigate} from 'react-router-dom'
import RecentEvents from '../components/RecentEvents';
import Login from '../modules/SignIn'
import api from '../redux/api'
import { addUser,loginUser } from '../redux/userSlice';
import { addEvent } from '../redux/eventSlice';
import Welcome from './../components/Welcome';

export default function Home() {
  const users = useSelector(state => state.users.items)
  const checkUser = window.localStorage.getItem('LOGIN_STATUS');
  const { userLoggedIn } = useSelector((state) => state.users);
  //const [userLoggedIn,setUserLoggedIn] = useState(false)

  return (
    <div>
     <div>
     { checkUser == 'true' ? 
      <div>
      <Welcome/>
      <RecentEvents/>
      </div>
      : 
      <Login/>
       }

      
     </div>
    
    </div>
  )
}
