import { createSlice } from '@reduxjs/toolkit'
import { act } from 'react-dom/test-utils'

export const userSlice = createSlice({
  name: 'users',
  initialState: {
    items: [],
    userLoggedIn:false,
    user:{},
    loading:false,
  },
  reducers: {
   
    addUser: (state, action) => {
      console.log('add user',action)
     
      // state.items.push(action.payload)
      state.userLoggedIn=true
     
      state.user= action.payload
      window.localStorage.setItem('LOGIN_STATUS',JSON.stringify(true))
      window.localStorage.setItem('CURRENT_USER',JSON.stringify(action.payload))
      
    },
    editUser: (state, action) => {
      // state.items.map(item => {
      //   if(item.id == action.payload.id)
      //   {
      //     item.title = action.payload.title
      //     item.description = action.payload.description
      //   }
      // })
     
    },
    deleteUser: (state, action) => {
      
      console.log('delete',action)
      //  state.items = state.items.filter(item => 
      //   item.id != action.payload.id
      //  )
      
    },
    loginUser:(state,action) => {
      state.loading=true
      state.userLoggedIn=true
      state.user= action.payload
      window.localStorage.setItem('LOGIN_STATUS',JSON.stringify(true))
      window.localStorage.setItem('CURRENT_USER',JSON.stringify(action.payload))
      console.log('login user',state.user)
    },
    logOutUser:(state) => {
     
      window.localStorage.setItem('LOGIN_STATUS',JSON.stringify(false))
      window.localStorage.setItem('CURRENT_USER',JSON.stringify([]))
      state.user=[]
      state.userLoggedIn=false

    }
  }
})

// Action creators are generated for each case reducer function
export const { addUser,editUser,deleteUser, loginUser,logOutUser} = userSlice.actions

export default userSlice.reducer