import React from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import {Link, NavLink, useNavigate} from 'react-router-dom'
import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
function Welcome() {

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} style={{margin:'1%'}}>
      <Carousel.Item >
        <div
          className="d-block w-100"
          style={{backgroundColor:'#83C5BE',height:'250px'}}
        >
        </div>
        <Carousel.Caption>
          <h3>Welcome To Egy Calendar</h3>
          <p>To See All Events</p>
          <Link to={'/events/'}>
             <Button style={{backgroundColor:'#023047',color:'#EDF6F9', border:'0'}}>All Events</Button>

          </Link>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item >
        <div
          className="d-block w-100"
          style={{backgroundColor:'#83C5BE',height:'250px'}}
        >
        </div>

        <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item >
        <div
          className="d-block w-100"
          style={{backgroundColor:'#83C5BE',height:'250px'}}
        >
        </div>

        <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default Welcome