import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faRightFromBracket, faFileInvoice,AiFillEyeInvisible } from '@fortawesome/free-solid-svg-icons'

import api from "../redux/api";
import { addUser, loginUser, logOutUser } from "../redux/userSlice";

export default function Header1() {
  const users = useSelector((state) => state.users.items);

  const checkUser = window.localStorage.getItem('LOGIN_STATUS');

  //const { userLoggedIn } = useSelector((state) => state.users);
  const [userLoggedIn,setUserLoggedIn] = useState(false)
  //const  currentUser  = useSelector((state) => state.users.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let currentUser =  window.localStorage.getItem('CURRENT_USER')
 // console.log(JSON.parse(currentUser))
  currentUser = currentUser ? JSON.parse(currentUser) : ''
  const cur   = currentUser ? String(currentUser.email).split(' ')[0] : ''

  const handleLogOut = (e) => {
    e.preventDefault()
    dispatch(logOutUser())
    navigate("/");
  }
  // useEffect(() => {
  //   const check = window.localStorage.getItem('LOGIN_STATUS');
  //   if(check == 'true')
  //   {
  //     console.log('vhevk vhevk',check)
  //     setUserLoggedIn(true)
  //   }
  //   else
  //   {
  //     setUserLoggedIn(false)
  //   }
  //   //setUserLoggedIn(JSON.parse(check));
    
    
  // }, []);
  // useEffect(() => {

  // },[currentUser])
 
  return (
    <div className="header1">
        <div style={{marginLeft:'1.5%',fontSize:'99%',fontWeight:'bold'}}>
        <NavLink to="/"  style={{  textDecoration : 'none',color:'#EDF6F9',listStyle: 'none'}}>
          <p >Egy Calendar </p>
        </NavLink>
          
        </div>
        <div style={{marginRight:'1.5%'}}>
          <ul className="header-list">
            <li className="pr-5">
              {checkUser =='true' ? (
                <div>
                <NavLink to="/"   style={{  textDecoration : 'none',color:'#EDF6F9',listStyle: 'none', padding:'5px'}}>
                  {" "}
                  Home
                </NavLink>
                </div>
              ) : (
                <NavLink to="/login" style={{  textDecoration : 'none',color:'#EDF6F9',listStyle: 'none', padding:'2px'}}> Login</NavLink>
              )}
            </li>
            <li className="pr-5">
              <div>
                  <NavLink to="/events"   style={{  textDecoration : 'none',color:'#EDF6F9',listStyle: 'none', padding:'5px'}}>
                    {" "}
                    Events
                  </NavLink>
                    {" "}
              </div>
            </li>
            <li className="pr-5">
            {checkUser ==='true' ? (
              <Dropdown style={{  textDecoration : 'none',color:'#023047',listStyle: 'none',paddingLeft:'5px'}}>
                    <Dropdown.Toggle style={{backgroundColor:'#EDF6F9',fontSize:'12px',color:'#023047',border:'0'}} id="dropdown-basic">
                    <FontAwesomeIcon icon={faUser} className='pr-5' />
                    {cur}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="/profile"  style={{  textDecoration : 'none',color:'#023047',listStyle: 'none', padding:'5px',fontSize:'13px'}}>
                          {" "}
                          <FontAwesomeIcon icon={faFileInvoice} /> My Account
                      </Dropdown.Item>
                      <Dropdown.Item
                          onClick={handleLogOut}  style={{  textDecoration : 'none',color:'#023047',listStyle: 'none', padding:'5px',fontSize:'13px'}}>
                          {" "}
                          <FontAwesomeIcon icon={faRightFromBracket} /> Log Out 
                      </Dropdown.Item>
                      {/* <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                      <Dropdown.Item href="#/action-3">Something else</Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
            ) :  ''
            }   
            </li>
          </ul>
        </div>
    
    </div>
  );
}
