import React from 'react'
import { useEffect } from 'react';
import api from '../../redux/api'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';

function PaymentCallback() {
   const queryString = window.location.search;
   const urlParams = new URLSearchParams(queryString);
   const payment_id = urlParams.get('id')
   const payment_success = urlParams.get('success')
   const amount_cents = urlParams.get('amount_cents')
   const order_id = urlParams.get('order')
   const navigate = useNavigate();

  // console.log(payment_id);
  let event_id =  JSON.parse(window.localStorage.getItem('EVENT_ID'))
  let numOfTickets =  JSON.parse(window.localStorage.getItem('NUM_OF_TICKETS'))

   useEffect(() => {
   //let user_token =   window.localStorage.getItem('CURRENT_USER_TOKEN')
   //console.log('payment_id')
   
   api.postData('ticket/payment/verification',{order_id,event_id}).then(res => {
    console.log('respoooooooonse',res)
    let paidAmount = res.data.paidAmount
    let eventPrice = res.data.eventPrice
    let final_price = numOfTickets * eventPrice
    if(res && res.status == 200)
    {
      console.log('paid', paidAmount , 'event price',eventPrice , 'final price',final_price)
      if(final_price == paidAmount)
      {
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          className: 'custom-toast',
        });
  
        navigate("/event/"+ event_id);
      }
      else if(final_price > paidAmount)
      {
        toast.error('The Amount Paid is less than the Ticket Price', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          className: 'custom-toast',
        });
      }
      else if(final_price < paidAmount)
      {
        toast.error('The Amount Paid is more than the Ticket Price', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          className: 'custom-toast',
        });
      }
     
    }
    else {
      toast.error(res.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: 'custom-toast',
      });
    }
   })

   },[])
  return (
    <div>
    {/* <Link to={'/event/'+ event_id}>
      <Button style={{backgroundColor:'#023047',color:'#CAF0F8', border:'0'}}>See Details</Button>

      </Link> */}
    </div>
  )
}

export default PaymentCallback