import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {Link, NavLink, useNavigate} from 'react-router-dom'
import api from '../../redux/api'
import { addEvent,editEvent,deleteEvent} from '../../redux/eventSlice';
import { Row, Col , Button,Form} from 'react-bootstrap';

function Add() {

  const [name,setName] = useState();
  const [name_local,setNameLocal] = useState();
  const [author,setAuthor] = useState();
  const [description,setDescription] = useState();
  const [price,setPrice] = useState();

  const eventData = {name,name_local,author,description,price}
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addEvent = (e) => {
    //console.log('here loading',loading)
    e.preventDefault()
    api.postData('events/add',eventData).then(res => {
     // console.log('here res',res.data)
      if(res.data.status == 'success')
      {
       
        //dispatch(loginUser({email,password}))
        //console.log('here loading',loading) 
        navigate("/events");
      }
     })
  }

  return (
    <div>
     <Form className='p-5'>
      <Row className="m-3">
      <Col md={6} lg={6} sm={12} className="p-1">
        {/* <Form.Label>Name</Form.Label> */}
        <input className="form-control" type="text" placeholder="Name" required onChange={(e)=> {setName(e.target.value)}}/>
      </Col>
      <Col md={6} lg={6} sm={12} className="p-1">
        {/* <Form.Label>Name</Form.Label> */}
        <input className="form-control" type="text" placeholder="Arabic Name" onChange={(e)=> {setNameLocal(e.target.value)}} />
      </Col>
     
       
      </Row>
      <Row className="m-3">
      <Col md={6} lg={6} sm={12} className="p-1">
        {/* <Form.Label>Name</Form.Label> */}
        <input className="form-control" type="text" placeholder="Description" required onChange={(e)=> {setDescription(e.target.value)}}/>
        
      </Col>
      <Col md={6} lg={6} sm={12} className="p-1">
        {/* <Form.Label>Name</Form.Label> */}
        <input className="form-control" type="text" placeholder="Author" required onChange={(e)=> {setAuthor(e.target.value)}}/>
        
      </Col>
     
      </Row>
      <Row className="m-3">
      <Col md={6} lg={6} sm={12} className="p-1">
        {/* <Form.Label>Name</Form.Label> */}
        <input className="form-control" type="text" placeholder="Price" required onChange={(e)=> {setPrice(e.target.value)}}/>
        
      </Col>
     
     
      </Row>
      <Row className="m-3">
      <Col> 

      </Col>
      <Col md={6} lg={6} sm={12} style={{display:'flex',justifyContent:'end'}}>
        <NavLink to='/events'>
          <Button variant="primary" 
                style={{backgroundColor:'#023047', color:'#EDF6F9', border:'0',marginRight:'15px'}}>
            Cancel
          </Button>
        </NavLink>
        <Button variant="primary" type="submit" onClick={addEvent}
              style={{backgroundColor:'#023047', color:'#EDF6F9', border:'0'}}>
          Submit
        </Button>
      </Col>
      </Row>

     
      
    </Form>
    
    </div>
  )
}

export default Add